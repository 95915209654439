import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Drawer, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import Tooltip from "@mui/material/Tooltip";
import FeedbackLoopItem from "./FeedbackLoopItem";
import {
  saveSelectedLoopArea,
  setLoopView,
  updateCycleStatus,
} from "../../redux/actions/nodeAction";
import { removeFocus } from "../../utils/global";

const charStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const FeedbackBar = ({
  open,
  onClose,
  focusedCycle,
  setFocuedCycle,
  setStatusOfVisibility,
  onPlayLoopOnce,
  onPlayLoopRepeat,
  // setCustomCycles,
  // customCycles,
  // setIsLengthOfFeedbackLoop,
  // isLengthOfFeedbackLoop,
}) => {
  //AllPlayloop
  const dispatch = useDispatch();
  const [customCycles, setCustomCycles] = useState([]);

  // console.log("🚀 ~ file: index.js:29 ~ customCycles:", customCycles);
  const {
    cycles,
    selectedMapData,
    loopView,
    createdNewMap,
    unUsepoint,
    isHideFully,
  } = useSelector((state) => state.node);

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onClose]);

  useEffect(() => {
    if (open) {
      removeFocus();
    }
  }, [open]);

  const getTypeOfCycle = (cycle) => {
    if (!selectedMapData) {
      return "";
    }

    const { ways } = selectedMapData;
    if (!ways) {
      return "";
    }
    let countOfOpposite = 0;
    let isGray = false;
    for (let i = 0; i < cycle.length - 1; i += 1) {
      const selectedWay = ways.find(
        (way) => way.points[0] === cycle[i] && way.points[1] === cycle[i + 1]
      );
      if (selectedWay && selectedWay.status === "Opposite") {
        countOfOpposite += 1;
      }
      if (selectedWay && selectedWay.status === "Default") {
        isGray = true;
      }
    }

    if (isGray) {
      return "Undefined Loop";
    }

    if (countOfOpposite % 2 === 0) {
      return "Reinforcing Loop";
    }

    return "Balancing Loop";
  };

  useEffect(() => {
    if (cycles) {
      let newCustomCycles = [];
      const originCycles = selectedMapData?.cycles || [];
      const loopView_length = cycles.filter(
        (cycle) => JSON.stringify(cycle) === JSON.stringify(loopView)
      );
      if (loopView_length.length === 0) {
        dispatch(setLoopView(loopView, isHideFully, unUsepoint));
      }
      originCycles.forEach((item) => {
        let existedItem = null;
        cycles.forEach((cycle) => {
          if (JSON.stringify(item.data) === JSON.stringify(cycle)) {
            existedItem = item;
          }
        });

        if (existedItem) {
          newCustomCycles = [
            ...newCustomCycles,
            { ...existedItem, type: getTypeOfCycle(item.data) },
          ];
        }
      });

      cycles.forEach((cycle, index) => {
        let existedItem = null;
        originCycles.forEach((item) => {
          if (JSON.stringify(item.data) === JSON.stringify(cycle)) {
            existedItem = item;
          }
        });

        if (!existedItem) {
          newCustomCycles = [
            ...newCustomCycles,
            {
              name: getNameOfCycle(newCustomCycles),
              data: cycle,
              type: getTypeOfCycle(cycle),
              view: false,
            },
          ];
          dispatch(updateCycleStatus(customCycles));
        }
      });
      setCustomCycles(newCustomCycles);
      // setIsLengthOfFeedbackLoop(newCustomCycles.length);
    } else {
      // setIsLengthOfFeedbackLoop(0);
      setCustomCycles([]);
    }
  }, [cycles, selectedMapData, dispatch, customCycles.length]);

  useEffect(() => {
    if (createdNewMap) {
      onClose();
      setFocuedCycle(null);
      dispatch(setLoopView(null, selectedMapData?.FullSpotlight, unUsepoint));
    }
  }, [createdNewMap, selectedMapData, dispatch]);

  const getNameOfCycle = (newCustomCycles) => {
    let index = 0;
    newCustomCycles.forEach((cycle) => {
      if (cycle.name && cycle.name.includes("Loop ")) {
        const afterPrefix = cycle.name.slice(5);
        if (afterPrefix.length === 1) {
          const offset = afterPrefix.charCodeAt(0) - 65;
          if (offset > -1 && offset < 26 && offset >= index) {
            index = offset + 1;
          }
        } else if (afterPrefix.length === 2) {
          const offset0 = afterPrefix.charCodeAt(0) - 65;
          const offset1 = afterPrefix.charCodeAt(1) - 65;
          if (offset0 > -1 && offset0 < 26 && offset1 > -1 && offset1 < 26) {
            const offset = (offset0 + 1) * 26 + offset1;
            if (offset >= index) {
              index = offset + 1;
            }
          }
        }
      }
    });
    if (index > 25) {
      return (
        "Loop " + charStr[Math.floor(index / 26) - 1] + charStr[index % 26]
      );
    }

    return "Loop " + charStr[index];
  };

  const handleChangeView = (cycle) => {
    const { data } = selectedMapData;
    let cyclePoints;
    if (cycle) {
      cyclePoints = cycle.data.map((id) =>
        data.find((point) => point.nodeId === id)
      );
    } else {
      let allFocusedCycle = [];
      focusedCycle &&
        focusedCycle.map((item) =>
          item.data.map((value) => allFocusedCycle.push(value))
        );

      cyclePoints = allFocusedCycle.map((id) =>
        data.find((point) => point.nodeId === id)
      );
    }
    const pointPositions = cyclePoints.map((point) => ({
      x: point.x,
      y: point.y,
    }));

    let minX = pointPositions[0].x,
      minY = pointPositions[0].y,
      maxX = pointPositions[0].x,
      maxY = pointPositions[0].y;
    pointPositions.forEach((point) => {
      minX = minX > point.x ? point.x : minX;
      minY = minY > point.y ? point.y : minY;
      maxX = maxX < point.x ? point.x : maxX;
      maxY = maxY < point.y ? point.y : maxY;
    });

    const centerPosition = { x: (minX + maxX) / 2, y: (minY + maxY) / 2 };
    const areaSize = { x: maxX - minX, y: maxY - minY };
    dispatch(saveSelectedLoopArea(centerPosition, areaSize));
  };

  const handleChangeName = (evt, cycle) => {
    const newCustomCycles = customCycles.map((item) => {
      if (JSON.stringify(item.data) === JSON.stringify(cycle.data)) {
        return {
          ...item,
          name: evt.target.value,
        };
      }
      return item;
    });
    setCustomCycles(newCustomCycles);
    // setIsLengthOfFeedbackLoop(newCustomCycles.length);
  };

  const handleChangedName = () => {
    customCycles.forEach((cycle, index) => {
      if (cycle.name === "") {
        let newName = "";
        if (selectedMapData.cycles) {
          const matchedCycle = selectedMapData.cycles.find(
            (it) => JSON.stringify(it.data) === JSON.stringify(cycle.data)
          );
          newName = matchedCycle
            ? matchedCycle.name
            : getNameOfCycle(customCycles);
        } else {
          newName = "Loop A";
        }
        const newCycle = {
          ...cycle,
          name: newName,
        };
        customCycles.splice(index, 1, newCycle);
      }
    });
    dispatch(updateCycleStatus(customCycles));
  };

  const handleChangeVisibility = (cycle) => {
    setStatusOfVisibility(null);
    if (focusedCycle === null) {
      setFocuedCycle([cycle]);
      dispatch(setLoopView(cycle.data, isHideFully, unUsepoint));
    } else {
      const fIndex = focusedCycle?.findIndex(
        (fcycle) => JSON.stringify(fcycle.data) === JSON.stringify(cycle.data)
      );

      let newFocusedCycles = [];
      if (fIndex > -1) {
        newFocusedCycles = focusedCycle.filter(
          (fcycle) => JSON.stringify(fcycle.data) !== JSON.stringify(cycle.data)
        );
        if (newFocusedCycles.length === 0) {
          setFocuedCycle(null);
        } else {
          setFocuedCycle(newFocusedCycles);
        }
      } else {
        newFocusedCycles = [...focusedCycle, cycle];
        setFocuedCycle(newFocusedCycles);
      }

      let newLoopView = [];
      newFocusedCycles.forEach((fcycle) => {
        newLoopView = [...newLoopView, ...fcycle.data];
      });
      dispatch(setLoopView(newLoopView, isHideFully, unUsepoint));
    }

    const newCustomCycles = customCycles.map((item) => {
      if (JSON.stringify(item.data) === JSON.stringify(cycle.data)) {
        return {
          ...item,
          view: !cycle.view,
        };
      }
      return item;
    });
    setCustomCycles(newCustomCycles);
    // setIsLengthOfFeedbackLoop(newCustomCycles.length);
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newCustomCycles = Array.from(customCycles);
    const movedCycle = customCycles[source.index];
    newCustomCycles.splice(source.index, 1);
    newCustomCycles.splice(destination.index, 0, movedCycle);

    dispatch(updateCycleStatus(newCustomCycles));
    setCustomCycles(newCustomCycles);
    // setIsLengthOfFeedbackLoop(newCustomCycles.length);
  };

  // useEffect(() => {
  //   if (isLengthOfFeedbackLoop !== undefined) {
  //     setIsLengthOfFeedbackLoop(customCycles?.length);
  //   }
  // }, [customCycles, isLengthOfFeedbackLoop]);

  return (
    <Drawer anchor="right" variant="persistent" open={open} onClose={onClose}>
      {/* <div
        id="global-backdrop"
        className="global-backdrop"
        style={{ display: "none" }}
      ></div> */}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="chatbar-container">
          <section className="chatbar-title">
            <h2 className="title title-sidebar">Feedback Loops</h2>
            {/* <div className="img" style={{display:(focusedCycle && focusedCycle.length>=1)?"flex":"none"}}>
              <Tooltip title="Zoom Selection to Fit" arrow 
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#505B6B',
                      fontSize:"13px",
                      '& .MuiTooltip-arrow': {
                        color: '#505B6B',
                        transform: "translate(68px, 0px) !important"
                      },
                    },
                  },
                }}
              >
                <img src={`/assets/ico-center${(focusedCycle && focusedCycle.length>=1)?"":"-b"}.svg`} alt="" onClick={()=>(focusedCycle && focusedCycle.length>=2)?handleChangeView():""}/>
              </Tooltip>
              <img src={`/assets/ico-Play${(focusedCycle && focusedCycle.length>=1)?"":"-b"}.svg`} alt="" onClick={()=>(focusedCycle && focusedCycle.length>=2)?AllPlayloop():""}/>
            </div> */}
            <IconButton onClick={onClose}>
              <CloseIcon className="btn-close" />
            </IconButton>
          </section>
          <Droppable droppableId="messagelist">
            {(provided) => (
              <section
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="messagelist-container"
              >
                {customCycles &&
                  customCycles.map((item, index) => (
                    <FeedbackLoopItem
                      key={index}
                      index={index}
                      data={item}
                      focusedCycle={focusedCycle}
                      loopView={loopView}
                      onChangeView={() => handleChangeView(item)}
                      onhandleBlur={handleChangedName}
                      onChangeName={(evt) => handleChangeName(evt, item)}
                      onChangeVisibility={() => handleChangeVisibility(item)}
                      onPlayLoopOnce={() => onPlayLoopOnce(item)}
                      onPlayLoopRepeat={() => onPlayLoopRepeat(item)}
                    />
                  ))}
                {provided.placeholder}
              </section>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </Drawer>
  );
};

export default FeedbackBar;
