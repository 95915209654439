import {
  MAP_LOAD_DATA_REQUEST,
  MAP_LOAD_DATA_SUCCESSED,
  MAP_LOAD_DATA_FAILED,
  SAVE_CURRENT_MAP_ID,
  SAVE_CURRENT_MAP_DATA,
  UPLOAD_CURRENT_MAP_REQUEST,
  UPLOAD_CURRENT_MAP_SUCCESS,
  UPLOAD_CURRENT_MAP_FAILED,
  CHECK_VALID_EMAIL_REQUEST,
  CHECK_VALID_EMAIL_SUCCESS,
  CHECK_VALID_EMAIL_FAILED,
  SET_ACTIVE_MAP,
  SAVE_CYCLES,
  SAVE_SELECTED_LOOP_AREA,
  SET_LOOP_VIEW,
  CLEAR_CURRENT_MAP_DATA,
  SHOW_LEVERAGE_LOOP,
  CREATED_NEW_MAP,
  CHANGE_COLOR_SYSTEM,
  CLEAR_MAPS,
  SAVE_TOKEN,
  CLONE_SUCCESS,
} from "../actionTypes";
import { sendEmailTo, getUserInfoByEmail } from "../../services";
import {
  mapsRef,
  storageRef,
  // authRef,
  databaseRef,
  tokenRef,
} from "../../core/firebase";
import { saveChatHistory } from "./chatAction";
import { GetNote } from "./noteAction";
import { deleteMouseMovement } from "./movementAction";

let unSubscribe = null;
let unSubscribeWays = null;
let unSubscribeNodes = null;

// custome functions
export const unSubscribes = (selectedMapId) => (dispatch, getState) => {
  unSubscribe = mapsRef
    .doc(selectedMapId)
    .collection("data")
    .onSnapshot(
      (snaps) => {
        const { selectedMapData } = getState().node;
        let tempData =
          selectedMapData && selectedMapData.data
            ? selectedMapData.data.slice()
            : [];
        if (snaps.docChanges().length !== 0) {
          snaps.docChanges().forEach((selectedDoc) => {
            if (selectedDoc.type === "added") {
              const existedTempNode = tempData.find(
                (node) => node.nodeId === selectedDoc.doc.data().nodeId
              );
              if (!existedTempNode) {
                tempData = [...tempData, selectedDoc.doc.data()];
              }
            } else if (selectedDoc.type === "modified") {
              const matchedIndex = tempData?.findIndex(
                (node) => node.nodeId === selectedDoc.doc.id
              );
              if (matchedIndex > -1) {
                tempData.splice(matchedIndex, 1, selectedDoc.doc.data());
              }
            } else if (selectedDoc.type === "removed") {
              const matchedIndex = tempData?.findIndex(
                (node) => node.nodeId === selectedDoc.doc.id
              );
              if (matchedIndex > -1) {
                tempData.splice(matchedIndex, 1);
              }
            }
          });
        }
        dispatch(saveCurrentMapData({ data: tempData }));
      },
      (err) => {
        dispatch(getNodesFailed());
      }
    );
};
export const unSubscribeWay = (selectedMapId) => (dispatch, getState) => {
  unSubscribeWays = mapsRef
    .doc(selectedMapId)
    .collection("ways")
    .onSnapshot(
      (snaps) => {
        const { selectedMapData } = getState().node;
        let tempData =
          selectedMapData && selectedMapData.ways
            ? selectedMapData.ways.slice()
            : [];
        if (snaps.docChanges().length !== 0) {
          snaps.docChanges().forEach((selectedDoc) => {
            if (selectedDoc.type === "added") {
              const existedTempWay = tempData.find(
                (node) => node.nodeId === selectedDoc.doc.data().nodeId
              );
              if (!existedTempWay) {
                tempData = [...tempData, selectedDoc.doc.data()];
              }
            } else if (selectedDoc.type === "modified") {
              const matchedIndex = tempData?.findIndex(
                (node) => node.nodeId === selectedDoc.doc.id
              );
              if (matchedIndex > -1) {
                tempData.splice(matchedIndex, 1, selectedDoc.doc.data());
              }
            } else if (selectedDoc.type === "removed") {
              const matchedIndex = tempData?.findIndex(
                (node) => node.nodeId === selectedDoc.doc.id
              );
              if (matchedIndex > -1) {
                tempData.splice(matchedIndex, 1);
              }
            }
          });
        }
        dispatch(saveCurrentMapData({ ways: tempData }));
      },
      (err) => {
        dispatch(getNodesFailed());
      }
    );
};

/** *********** Get all Maps from fireStore *********** **/
export const getNodes = (sortWay) => (dispatch, getState) => {
  dispatch(clearMaps());

  if (getState().auth && getState().auth.email) {
    const { email } = getState().auth;

    unSubscribeNodes = mapsRef
      .orderBy(sortWay === "desc" ? "updatedAt" : "createdAt", "desc")
      .onSnapshot(
        (querySnapshot) => {
          const res = [];
          const { selectedMapId } = getState().node;
          dispatch(getNodesRequest());

          querySnapshot.forEach((doc) => {
            const subMaps = {
              ...doc.data(),
              id: doc.id,
            };
            if (
              (subMaps.creator &&
                subMaps.creator.find((user) => user.email === email)) ||
              subMaps.type === "Template"
            ) {
              res.push(subMaps);
            }

            if (selectedMapId && doc.id === selectedMapId) {
              if (
                doc.data().creator &&
                doc.data().creator.find((user) => user.email === email)
              ) {
                dispatch(saveCurrentMapData(subMaps));
              } else {
                dispatch(deleteMouseMovement());
                dispatch(saveCurrentMapID(null));
                dispatch(clearCurrentMapData());
                dispatch(setActivemap(false));
              }
            }
          });
          dispatch(getNodesSuccess(res));
        },
        (err) => {
          dispatch(getNodesFailed());
        }
      );
  }
};

export const getMapsByToken = (token) => (dispatch, getState) => {
  const docRef = tokenRef.doc(token);
  docRef.get().then(async (snapshot) => {
    const mapData = snapshot.data();

    //Add cloning users in Template
    var map = [];
    const mapsRefSnapShot = await mapsRef.get();
    mapsRefSnapShot.docs.forEach((doc) => {
      if (doc.data().id === mapData.map_id) {
        map.push(doc.data());
      }
    });

    var newCloning = map[0]?.cloning_users;
    var docData = [];
    if (newCloning === undefined) {
      docData.push({ user_id: localStorage.getItem("user_id") });
    } else {
      var objIndex = newCloning?.findIndex(
        (obj) => obj.user_id === localStorage.getItem("user_id")
      );
      if (objIndex === -1) {
        docData.push(...newCloning, {
          user_id: localStorage.getItem("user_id"),
        });
      }
    }
    mapsRef.doc(map[0].id).update({ cloning_users: docData });
    //End Add cloning users

    const selectedMapId = mapData.map_id;
    if (selectedMapId) {
      dispatch(unSubscribes(selectedMapId));
      dispatch(unSubscribeWay(selectedMapId));
      dispatch(saveCurrentMapID(selectedMapId));
      dispatch(saveChatHistory(selectedMapId));
      dispatch(GetNote(selectedMapId));
      dispatch(setActivemap(true));
      mapsRef.doc(selectedMapId).onSnapshot((snapshot) => {
        dispatch(saveCurrentMapData(snapshot.data()));
      });
    }
  });
};
//for share link only
export const getMapsByCloneID = (id) => (dispatch, getState) => {
  const selectedMapId = id;
  if (selectedMapId) {
    dispatch(unSubscribes(selectedMapId));
    dispatch(unSubscribeWay(selectedMapId));
    dispatch(saveCurrentMapID(selectedMapId));
    dispatch(saveChatHistory(selectedMapId));
    dispatch(GetNote(selectedMapId));
    dispatch(setActivemap(true));
    mapsRef.doc(selectedMapId).onSnapshot((snapshot) => {
      dispatch(saveCurrentMapData(snapshot.data()));
    });
  }
};
export const getMaps = () => (dispatch, getState) => {
  if (getState().auth && getState().auth.email) {
    const { selectedMapId } = getState().node;

    if (selectedMapId) {
      dispatch(unSubscribes(selectedMapId));
      dispatch(unSubscribeWay(selectedMapId));
    }
  }
};

export const getNodesRequest = () => ({
  type: MAP_LOAD_DATA_REQUEST,
});

export const getNodesSuccess = (payload) => ({
  type: MAP_LOAD_DATA_SUCCESSED,
  payload,
});

export const getNodesFailed = () => ({
  type: MAP_LOAD_DATA_FAILED,
});

export const loadMapWithHistoryId = (historyId) => (dispatch, getState) => {
  const { selectedMapId } = getState().node;
  const historyDB = mapsRef.doc(selectedMapId).collection("history");
  historyDB
    .doc(historyId)
    .get()
    .then((historySnapShot) => {
      if (historySnapShot) {
        dispatch(saveCurrentMapData(historySnapShot.data()));
      }
    });
};

/** *********** Select Map *********** **/
export const selectMap = (id, token, email) => (dispatch, getState) => {
  dispatch(saveCurrentMapID(id));
  dispatch(saveChatHistory(id));
  dispatch(GetNote(id));
  dispatch(setActivemap(true));
  if (token) {
    dispatch({ type: SAVE_TOKEN, payload: token });
  }
  if (getState().node && getState().node.maps) {
    const allMapData = getState().node.maps;
    const selectedMap = allMapData.find((map) => map.id === id);
    selectedMap.tag = getState().auth.permission;

    //add user in map for green dot
    const newCreator = selectedMap && selectedMap.creator.slice();
    var objIndex = newCreator?.findIndex((obj) => obj.email === email);
    newCreator[objIndex].currentlyInMap = true;
    const selectedMapData = { ...selectedMap, creator: newCreator };

    mapsRef
      .doc(selectedMap.id)
      .update(selectedMapData)
      .then((snap) => {
        dispatch(saveCurrentMapData(selectedMapData));
        dispatch(uploadMapDataSuccess());
      })
      .catch(() => {
        dispatch(uploadMapDataFailed());
      });
  }
};

export const saveCurrentMapID = (id) => ({
  type: SAVE_CURRENT_MAP_ID,
  payload: id,
});

export const saveCurrentMapData = (mapData) => ({
  type: SAVE_CURRENT_MAP_DATA,
  payload: mapData,
});

export const clearCurrentMapData = () => (dispatch) => {
  if (unSubscribe) {
    unSubscribe();
  }
  if (unSubscribeWays) {
    unSubscribeWays();
  }

  dispatch({
    type: CLEAR_CURRENT_MAP_DATA,
  });
};

export const setActivemap = (b) => ({
  type: SET_ACTIVE_MAP,
  payload: b,
});

const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);

  var ab = new ArrayBuffer(byteCharacters.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteCharacters.length; i++) {
    ia[i] = byteCharacters.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var bb = new Blob([ab], { type: contentType });
  return bb;
};

export const uploadMapImage = (canvasImage) => async (dispatch, getState) => {
  if (!getState().auth.email || !getState().node.isActiveMap) {
    return;
  }
  dispatch(uploadMapDataRequest());
  const { selectedMapId } = getState().node;
  const block = canvasImage?.split(";");
  const contentType = block[0].split(":")[1];
  const realData = block[1].split(",")[1];
  const blob = b64toBlob(realData, contentType);
  try {
    await storageRef.child(`files/${selectedMapId}`).put(blob);

    // if (!selectedMapData.image) {
    const downloadURL = await storageRef
      .child(`files/${selectedMapId}`)
      .getDownloadURL();
    await mapsRef.doc(selectedMapId).update({ image: downloadURL });
    // }
    dispatch(uploadMapDataSuccess());
  } catch (err) {
    dispatch(uploadMapDataFailed());
  }
};

export const updateCycleStatus = (cycles) => async (dispatch, getState) => {
  dispatch(uploadMapDataRequest());
  try {
    const { selectedMapId } = getState().node;
    await mapsRef.doc(selectedMapId).update({ cycles });
    dispatch(uploadMapDataSuccess());
  } catch (err) {
    dispatch(uploadMapDataFailed());
  }
};

/** ********* Upload map data ********** **/
export const uploadMapData =
  (mapData, folderId = null) =>
  async (dispatch, getState) => {
    dispatch(uploadMapDataRequest());
    const { selectedMapId, selectedMapData: currentMapData } = getState().node;
    const { uid, avatarColor } = getState().auth;

    try {
      const { permission } = getState().auth;
      if (!selectedMapId) {
        const addedDoc = await mapsRef.add({
          createdAt: new Date(),
          updatedAt: new Date(),
          creator: [
            {
              email: getState().auth.email,
              displayName: getState().auth.displayName,
              dropvalue: "Owner",
              color: avatarColor ? avatarColor?.avatar_color : "",
              currentlyInMap: true,
            },
          ],
          author: getState().auth.email,
          tag: permission,
          type: mapData === "Template" ? "Template" : "",
          folders: folderId ? { [uid]: folderId } : { [uid]: "" },
          name: "My Map",
        });

        addedDoc.update({ id: addedDoc.id });
        dispatch(saveCurrentMapID(addedDoc.id));
        dispatch(saveChatHistory(addedDoc.id));
        dispatch(GetNote(selectedMapId));
      } else {
        const docRef = mapsRef.doc(currentMapData.id);
        // //////////////////////////////////////////////////
        /**
         * Update MapData
         */
        const updatedData = [...mapData.data, ...mapData.ways];
        const batch = databaseRef.batch();
        updatedData.forEach((uploadObj) => {
          let doc = null;
          if (
            uploadObj.method === "new point" ||
            uploadObj.method === "update point"
          ) {
            doc = docRef.collection("data").doc(uploadObj.updatedObj.nodeId);
            batch.set(doc, uploadObj.updatedObj);
          } else if (
            uploadObj.method === "new way" ||
            uploadObj.method === "update way"
          ) {
            doc = docRef.collection("ways").doc(uploadObj.updatedObj.nodeId);
            batch.set(doc, uploadObj.updatedObj);
          } else if (uploadObj.method === "delete point") {
            doc = docRef.collection("data").doc(uploadObj.updatedObj.nodeId);
            batch.delete(doc);
          } else if (uploadObj.method === "delete way") {
            doc = docRef.collection("ways").doc(uploadObj.updatedObj.nodeId);
            batch.delete(doc);
          }
        });
        if (
          currentMapData.creator.find(
            (user) => user.email === getState().auth.email
          )
        ) {
          docRef.update({ updatedAt: new Date() });
        }
        await batch.commit();
      }
      dispatch(uploadMapDataSuccess());
    } catch (err) {
      console.log("uploading error: ", err);
      dispatch(uploadMapDataFailed());
    }
  };

export const saveAsATemplate =
  (docId, userId, folderId, mapInfo) => async (dispatch, getState) => {
    try {
      const { avatarColor, email, displayName } = getState().auth;
      const docRef = mapsRef.doc(docId);
      const docData = (await docRef.get()).data();
      const { folders } = docData;
      const newFolders = { ...folders, [userId]: folderId };

      const addedDoc = await mapsRef.add({
        createdAt: new Date(),
        updatedAt: new Date(),
        creator: [
          {
            email: email,
            displayName: displayName,
            dropvalue: "Owner",
            color: avatarColor && avatarColor?.avatar_color,
            currentlyInMap: false,
          },
        ],
        image: mapInfo?.image,
        cycles: mapInfo?.cycles || [],
        author: email,
        name: mapInfo.name,
        folders: newFolders,
        type: "Template",
      });
      await addedDoc.update({ id: addedDoc.id });

      try {
        if (mapInfo.image) {
          const downloadedFile = await fetch(mapInfo.image);
          const blobFile = await downloadedFile.blob();
          await storageRef.child(`files/${addedDoc.id}`).put(blobFile);
          const uploadedUrl = await storageRef
            .child(`files/${addedDoc.id}`)
            .getDownloadURL();
          await addedDoc.update({ image: uploadedUrl });
        }
      } catch (err) {
        console.log("template map error: ", err);
      }

      const dataQuery = await mapsRef.doc(mapInfo.id).collection("data").get();
      const waysQuery = await mapsRef.doc(mapInfo.id).collection("ways").get();

      for (let i = 0; i < dataQuery.docs.length; i++) {
        await addedDoc
          .collection("data")
          .doc(dataQuery.docs[i].data().nodeId)
          .set(dataQuery.docs[i].data());
      }
      for (let i = 0; i < waysQuery.docs.length; i++) {
        await addedDoc
          .collection("ways")
          .doc(waysQuery.docs[i].data().nodeId)
          .set(waysQuery.docs[i].data());
      }
    } catch (error) {}
  };

export const moveMapToFolder =
  (docId, userId, folderId, type) => async (dispatch) => {
    if (!docId || !folderId) {
      return;
    }
    try {
      dispatch(uploadMapDataRequest());
      const docRef = mapsRef.doc(docId);
      const docData = (await docRef.get()).data();
      const { folders } = docData;
      const newFolders = { ...folders, [userId]: folderId };
      await docRef.update({
        folders: newFolders,
        type: type === "Templates" ? "Template" : null,
      });
      dispatch(uploadMapDataSuccess());
    } catch (err) {
      dispatch(uploadMapDataFailed());
    }
  };

export const removeMapFromFolder = (docId, userId) => async (dispatch) => {
  if (!docId) {
    return;
  }
  try {
    dispatch(uploadMapDataRequest());
    const docRef = mapsRef.doc(docId);
    const docData = (await docRef.get()).data();
    const { folders } = docData;
    const newFolders = { ...folders, [userId]: "" };
    await docRef.update({ folders: newFolders });
    dispatch(uploadMapDataSuccess());
  } catch (err) {
    dispatch(uploadMapDataFailed());
  }
};

export const updateMap = (updatedObj, method) => async (dispatch, getState) => {
  const { selectedMapId } = getState().node;
  if (!selectedMapId) {
    return;
  }

  try {
    dispatch(uploadMapDataRequest());
    if (method === "new point" || method === "update point") {
      await mapsRef
        .doc(selectedMapId)
        .collection("data")
        .doc(updatedObj.nodeId)
        .set(updatedObj);
    } else if (method === "new way" || method === "update way") {
      await mapsRef
        .doc(selectedMapId)
        .collection("ways")
        .doc(updatedObj.nodeId)
        .set(updatedObj);
    } else if (method === "delete point") {
      await mapsRef
        .doc(selectedMapId)
        .collection("data")
        .doc(updatedObj.nodeId)
        .delete();
    } else if (method === "delete way") {
      await mapsRef
        .doc(selectedMapId)
        .collection("ways")
        .doc(updatedObj.nodeId)
        .delete();
    }

    dispatch(uploadMapDataSuccess());
  } catch (err) {
    dispatch(uploadMapDataFailed());
  }
};

export const UpdateFullSpotlight = (data) => async (dispatch, getState) => {
  const { selectedMapId } = getState().node;
  dispatch(uploadMapDataRequest());

  mapsRef
    .doc(selectedMapId)
    .update({
      FullSpotlight: data,
      updatedAt: new Date(),
    })
    .then(() => {
      dispatch(uploadMapDataSuccess());
    })
    .catch(() => {
      dispatch(uploadMapDataFailed());
    });
};

export const User_CurrentlyInMap = (email, inMap) => (dispatch, getState) => {
  const { selectedMapData } = getState().node;
  const newCreator = selectedMapData && selectedMapData?.creator.slice();
  var objIndex = newCreator?.findIndex((obj) => obj.email === email);
  if (newCreator?.length > 0 && newCreator[objIndex] !== null) {
    newCreator[objIndex].currentlyInMap = inMap;
    dispatch(uploadMapDataRequest());

    mapsRef
      .doc(selectedMapData.id)
      .update({ creator: newCreator, updatedAt: new Date() })
      .then(() => {
        dispatch(uploadMapDataSuccess());
      })
      .catch((error) => {
        dispatch(uploadMapDataFailed());
      });
  }
};

export const feedbackCounter = () => (dispatch, getState) => {
  const { selectedMapData, selectedMapId } = getState().node;
  const { email } = getState().auth;
  let showdBy = [];

  selectedMapData?.cycles &&
    selectedMapData.cycles.forEach((element) => {
      if (!element?.showdBy || element?.showdBy.length === 0) {
        showdBy.push({
          ...element,
          showdBy: [email],
        });
      } else if (!element?.showdBy.includes(email)) {
        showdBy.push({
          ...element,
          showdBy: [...element.showdBy, email],
        });
      } else {
        showdBy.push({ ...element });
      }
    });
  // const cy = selectedMapData?.cycles?.map((item, index) => {
  //   if (!item?.showdBy || item?.showdBy.length === 0) {
  //     showdBy.push({
  //       ...item,
  //       showdBy: [email],
  //     });
  //   } else if (!item?.showdBy.includes(email)) {
  //     showdBy.push({
  //       ...item,
  //       showdBy: [...item.showdBy, email],
  //     });
  //   } else {
  //     showdBy.push({ ...item });
  //   }
  // });
  if (selectedMapId) {
    mapsRef
      .doc(selectedMapId)
      .update({ cycles: showdBy })
      .then(() => {
        dispatch(uploadMapDataSuccess());
      });
  }
};
export const uploadMapDataRequest = () => ({
  type: UPLOAD_CURRENT_MAP_REQUEST,
});

export const uploadMapDataSuccess = () => ({
  type: UPLOAD_CURRENT_MAP_SUCCESS,
});

export const uploadMapDataFailed = () => ({
  type: UPLOAD_CURRENT_MAP_FAILED,
});

export const duplicateMap =
  (mapInfo, Open_as_new_map = false, share_template = false) =>
  async (dispatch, getState) => {
    const addedDoc = await mapsRef.add({
      createdAt: new Date(),
      updatedAt: new Date(),
      creator: [
        {
          email: getState().auth.email,
          displayName: getState().auth.displayName,
          dropvalue: "Owner",
          color: mapInfo.creator[0].color,
          currentlyInMap: false,
        },
      ],
      image: mapInfo?.image,
      cycles: mapInfo.cycles || [],
      author: getState().auth.email,
      name: share_template
        ? mapInfo.name
        : Open_as_new_map
        ? "New map"
        : `Copy of ${mapInfo.name}`,
      folders: share_template
        ? mapInfo.folders || {}
        : Open_as_new_map
        ? {}
        : mapInfo.folders || {},
      type: share_template
        ? mapInfo?.type || ""
        : Open_as_new_map
        ? ""
        : mapInfo?.type || "",
    });

    if (mapInfo.type === "Template") {
      dispatch({ type: CLONE_SUCCESS, payload: addedDoc });
    }
    await addedDoc.update({ id: addedDoc.id });

    try {
      if (mapInfo.image && share_template) {
        const downloadedFile = new Blob([mapInfo.image]);
        await storageRef.child(`files/${addedDoc.id}`).put(downloadedFile);
        const uploadedUrl = await storageRef
          .child(`files/${addedDoc.id}`)
          .getDownloadURL();
        await addedDoc.update({ image: uploadedUrl });
      }
    } catch (err) {
      console.log("duplicate map error: ", err);
    }

    const dataQuery = await mapsRef.doc(mapInfo.id).collection("data").get();
    const waysQuery = await mapsRef.doc(mapInfo.id).collection("ways").get();

    for (let i = 0; i < dataQuery.docs.length; i++) {
      await addedDoc
        .collection("data")
        .doc(dataQuery.docs[i].data().nodeId)
        .set(dataQuery.docs[i].data());
    }
    for (let i = 0; i < waysQuery.docs.length; i++) {
      await addedDoc
        .collection("ways")
        .doc(waysQuery.docs[i].data().nodeId)
        .set(waysQuery.docs[i].data());
    }
  };

export const renameMapTitle = (payload) => (dispatch, getState) => {
  const { selectedMapId } = getState().node;
  if (selectedMapId) {
    dispatch(uploadMapDataRequest());
    const docRef = mapsRef.doc(selectedMapId);
    docRef
      .get()
      .then((snapshot) => {
        const mapData = snapshot.data();
        if (mapData) {
          docRef
            .update({
              updatedAt: new Date(),
              name: payload,
            })
            .then(() => {
              dispatch(uploadMapDataSuccess());
            })
            .catch(() => {
              dispatch(uploadMapDataFailed());
            });
        }
      })
      .catch(() => {
        dispatch(uploadMapDataFailed());
      });
  }
};

export const renameMapTitleWithMapInfo =
  (mapInfo, newTitle) => (dispatch, getState) => {
    const { id } = mapInfo;
    dispatch(uploadMapDataRequest());
    const docRef = mapsRef.doc(id);
    docRef
      .get()
      .then((snapshot) => {
        const mapData = snapshot.data();
        if (mapData) {
          docRef
            .update({
              // updatedAt: new Date(),
              name: newTitle,
            })
            .then(() => {
              dispatch(uploadMapDataSuccess());
            })
            .catch(() => {
              dispatch(uploadMapDataFailed());
            });
        }
      })
      .catch(() => {
        dispatch(uploadMapDataFailed());
      });
  };

/** **/
export const removePartipant = (email) => (dispatch, getState) => {
  const { selectedMapData } = getState().node;
  const newCreator = selectedMapData.creator.filter(
    (creator) => creator.email !== email
  );
  // const newSelectedMapData = {
  //   ...selectedMapData,
  //   creator: newCreator,
  //   updatedAt: new Date(),
  // };
  dispatch(uploadMapDataRequest());

  mapsRef
    .doc(selectedMapData.id)
    .update({ creator: newCreator, updatedAt: new Date() })
    .then(() => {
      dispatch(uploadMapDataSuccess());
    })
    .catch(() => {
      dispatch(uploadMapDataFailed());
    });
};

export const removePartipantById = (id) => (dispatch, getState) => {
  const { selectedMapId, selectedMapData } = getState().node;
  const newCreator = selectedMapData?.creator.filter(
    (creator) => creator?.random_id !== id
  );
  // const newSelectedMapData = {
  //   ...selectedMapData,
  //   creator: newCreator,
  //   updatedAt: new Date(),
  // };
  dispatch(uploadMapDataRequest());
  dispatch(saveCurrentMapData({ ...selectedMapData, creator: newCreator }));
  sessionStorage.clear();
  mapsRef
    .doc(selectedMapId)
    .update({ creator: newCreator, updatedAt: new Date() })
    .then((res) => {
      dispatch(uploadMapDataSuccess());
    })
    .catch(() => {
      dispatch(uploadMapDataFailed());
    });
};

export const updatePartipant =
  (email, dropvalue, memberIndex) => (dispatch, getState) => {
    const { selectedMapId, selectedMapData } = getState().node;
    const newCreator = selectedMapData.creator.slice();

    if (dropvalue === "Owner") {
      const objIndex = newCreator?.findIndex(
        (obj) => obj.dropvalue === dropvalue
      );
      newCreator[objIndex].dropvalue = "Editor";
      const objIndex1 = newCreator?.findIndex((obj) => obj.email === email);
      newCreator[objIndex1].dropvalue = dropvalue;
    }

    const objIndex = newCreator?.findIndex((obj) => obj.email === email);

    //Update object's dropvalue property.
    if (email) {
      newCreator[objIndex].dropvalue = dropvalue;
    } else {
      newCreator[memberIndex].dropvalue = dropvalue;
    }

    // const newSelectedMapData = {
    //   ...selectedMapData,
    //   creator: newCreator,
    //   updatedAt: new Date(),
    // };

    dispatch(uploadMapDataRequest());

    mapsRef
      .doc(selectedMapId)
      .update({
        creator: newCreator,
        updatedAt: new Date(),
        author: dropvalue === "Owner" ? email : selectedMapData.author,
      })
      .then(() => {
        dispatch(uploadMapDataSuccess());
      })
      .catch(() => {
        dispatch(uploadMapDataFailed());
      });
  };

export const update_user_Color = (email, id, color) => (dispatch, getState) => {
  const { selectedMapData } = getState().node;
  const newCreator = selectedMapData.creator.slice();

  var objIndex;
  if (id === undefined) {
    objIndex = newCreator?.findIndex((obj) => obj.email === email);
  } else {
    objIndex = newCreator?.findIndex((obj) => obj.random_id === id);
  }
  //Update object's color property.
  newCreator[objIndex].color = color;

  // const newSelectedMapData = {
  //   ...selectedMapData,
  //   creator: newCreator,
  //   updatedAt: new Date(),
  // };

  dispatch(uploadMapDataRequest());

  mapsRef
    .doc(selectedMapData.id)
    .update({ creator: newCreator, updatedAt: new Date() })
    .then(() => {
      dispatch(uploadMapDataSuccess());
    })
    .catch(() => {
      dispatch(uploadMapDataFailed());
    });
};

// Tempalate remove in your template folder
// Remove template -- other user template map
export const templateRemove = (data) => (dispatch, getState) => {
  var newMapData;
  if (!data?.removeTemplate) {
    newMapData = {
      removeTemplate: [{ emailId: localStorage.getItem("email") }],
    };
  } else {
    newMapData = {
      removeTemplate: [
        ...data?.removeTemplate,
        { emailId: localStorage.getItem("email") },
      ],
    };
  }
  mapsRef
    .doc(data.id)
    .update(newMapData)
    .then(() => {
      dispatch(uploadMapDataSuccess());
    });
};

export const update_chat_Icon_Color =
  (email, color) => (dispatch, getState) => {
    const { chatHistory } = getState().chat;
    const { selectedMapData } = getState().node;
    const newChatIcon = chatHistory;

    var objIndex = [];
    newChatIcon.forEach((element, index) => {
      if (element.email === email) {
        objIndex.push(index);
      }
    });
    objIndex.forEach((element) => {
      newChatIcon[element].color = color;
    });
    // newChatIcon.map((item, index) => {
    //   if (item.email === email) {
    //     objIndex.push(index);
    //   }
    // });
    // objIndex.map((item) => {
    //   newChatIcon[item].color = color;
    // });

    mapsRef
      .doc(selectedMapData.id)
      .collection("chat")
      .get()
      .then((addedDoc) => {
        addedDoc.docs.forEach((element, index) => {
          mapsRef
            .doc(selectedMapData.id)
            .collection("chat")
            .doc(addedDoc.docs[index].id)
            .update(newChatIcon[index])
            .then((snapshot) => {});
        });
        // addedDoc.docs.map((chats, index) => {
        //   mapsRef
        //     .doc(selectedMapData.id)
        //     .collection("chat")
        //     .doc(addedDoc.docs[index].id)
        //     .update(newChatIcon[index])
        //     .then((snapshot) => {});
        // });
      })
      .catch(() => {
        dispatch(uploadMapDataFailed());
      });
  };

export const add_token =
  (map_id, map_name, user_id, setdrop) => async (dispatch) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    await tokenRef.doc(result).set({
      map_id: map_id,
      map_name: map_name,
      user_id: user_id,
      drop_value: setdrop,
    });
    dispatch({ type: SAVE_TOKEN, payload: result });
  };

export const update_Token = (id, setdrop) => async (dispatch, getState) => {
  const docRef = tokenRef.doc(id);
  docRef.get().then((snapshot) => {
    const mapData = snapshot.data();

    //Update object's drop_value property.
    mapData.drop_value = setdrop;

    // const newSelectedMapData = {
    //   mapData,
    // };
    dispatch(uploadMapDataRequest());

    tokenRef
      .doc(id)
      .update(mapData)
      .then(() => {
        dispatch(uploadMapDataSuccess());
      })
      .catch(() => {
        dispatch(uploadMapDataFailed());
      });
  });
};

export const addPartipant =
  (emails, dropvalue, isGuest = false) =>
  async (dispatch, getState) => {
    const { selectedMapData } = getState().node;
    const newCreator = selectedMapData.creator.slice();
    let isValidEmails = [];
    dispatch({ type: CHECK_VALID_EMAIL_REQUEST });
    for await (const [i, email] of emails.entries()) {
      try {
        var regexEmail =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email && !regexEmail.test(email)) {
          isValidEmails.push({
            email: email,
            index: i,
            error: "You have entered an invalid email address",
          });
        }
      } catch (error) {
        isValidEmails.push({ email: email, index: i, error: "" });
      }
    }

    // selectedMapData.creator.map((item) => {
    //   return emails.map((item1, index) => {
    //     if (item.email === item1) {
    //       return isValidEmails.push({
    //         email: item1,
    //         index: index,
    //         error: "Already invited",
    //       });
    //     }
    //   });
    // });
    selectedMapData.creator.forEach((item) => {
      emails.forEach((item1, index) => {
        if (item.email === item1) {
          isValidEmails.push({
            email: item1,
            index: index,
            error: "Already invited",
          });
        }
      });
    });
    if (isValidEmails.length > 0) {
      dispatch({ type: CHECK_VALID_EMAIL_FAILED, payload: isValidEmails });
      return;
    }
    dispatch({ type: CHECK_VALID_EMAIL_SUCCESS });

    emails.forEach((email) => {
      getUserInfoByEmail({ email }).then((userInfo) => {
        let displayName = "";
        if (userInfo?.status === "success") {
          displayName = userInfo.data.displayName || "";
        } else {
          displayName = email.substring(0, email.lastIndexOf("@"));
        }

        if (!newCreator.includes(email) && email !== "") {
          sendEmailTo({
            to: email,
            from: {
              email: process.env.REACT_APP_ADMIN_ACCOUNT,
              name: "Cauzality",
            },
            subject: "You've been invited to collaborate on a map",
            html: `
              Hi ${
                userInfo?.status === "success"
                  ? displayName.split(" ")[0]
                  : "there"
              },
              <br/><br/>
              Someone just invited you to join their map in Cauzality. Follow this link to start collaborating:<br/><br/>
                <a href="${process.env.REACT_APP_PUBLIC_URL}/join/${
              selectedMapData.token
            }-${selectedMapData.name
              .replace(/[^a-zA-Z ^0-9]/g, "")
              .trim()
              .replace(/ /g, "-")}">
                
                  ${process.env.REACT_APP_PUBLIC_URL}/join/${
              selectedMapData.token
            }-${selectedMapData.name
              .replace(/[^a-zA-Z ^0-9]/g, "")
              .trim()
              .replace(/ /g, "-")}
                
                </a>
                <br/><br/>
              - Cauzality Team
              <br/><br/>
              P.S. Don't know about Cauzality yet? <a href="https://cauzality.com/">Learn more here</a>.
            `,
          });
          if (userInfo?.status === "success") {
            newCreator.push({
              email,
              displayName,
              dropvalue,
              currentlyInMap: isGuest,
            });
          }
          dispatch(uploadMapDataRequest());

          mapsRef
            .doc(selectedMapData.id)
            .update({ creator: newCreator, updatedAt: new Date() })
            .then((snap) => {
              dispatch(uploadMapDataSuccess());
            })
            .catch(() => {
              dispatch(uploadMapDataFailed());
            });
        }
      });
    });
  };

export const addGuestPartipant =
  (displayName, dropvalue, random_id) => async (dispatch, getState) => {
    const { selectedMapId, selectedMapData } = getState().node;
    if (selectedMapData !== undefined) {
      const newCreator = selectedMapData.creator.slice();
      var email = "";
      var color = "";
      var currentlyInMap = true;
      var timestamp = Date.now();
      newCreator.push({
        email,
        displayName,
        dropvalue,
        random_id,
        color,
        timestamp,
        currentlyInMap,
      });

      dispatch(uploadMapDataRequest());
      mapsRef
        .doc(selectedMapId)
        .update({ creator: newCreator, updatedAt: new Date() })
        .then(() => {
          dispatch(uploadMapDataSuccess());
        })
        .catch(() => {
          dispatch(uploadMapDataFailed());
        });
    }
  };

export const updateGuestTimestamp =
  (random_id) => async (dispatch, getState) => {
    const { selectedMapId, selectedMapData } = getState().node;
    if (selectedMapData?.creator !== undefined) {
      const newCreator = selectedMapData.creator.slice();

      var objIndex = newCreator?.findIndex(
        (obj) => obj.random_id === random_id
      );
      //Update object's timestamp property.
      if (objIndex !== -1) {
        var timestamp = Date.now();
        newCreator[objIndex].timestamp = timestamp;

        dispatch(uploadMapDataRequest());
        mapsRef
          .doc(selectedMapId)
          .update({ creator: newCreator, updatedAt: new Date() })
          .then(() => {
            dispatch(uploadMapDataSuccess());
          })
          .catch(() => {
            dispatch(uploadMapDataFailed());
          });
      }
    }
  };

export const addPartipantAfterLogin =
  (email, displayName, dropvalue) => async (dispatch, getState) => {
    const { selectedMapId, selectedMapData } = getState().node;
    if (selectedMapData !== undefined) {
      const isNewCreate = selectedMapData.creator.some(
        (item) => item.email === email
      );

      if (isNewCreate) {
        return true;
      }

      const newCreator = selectedMapData.creator.slice();
      const timestamp = Date.now();
      var currentlyInMap = true;

      newCreator.push({
        email,
        displayName,
        dropvalue,
        timestamp,
        currentlyInMap,
      });

      dispatch(uploadMapDataRequest());
      mapsRef
        .doc(selectedMapId)
        .update({ creator: newCreator, updatedAt: new Date() })
        .then(() => {
          dispatch(uploadMapDataSuccess());
        })
        .catch(() => {
          dispatch(uploadMapDataFailed());
        });
    }
  };

export const deleteMap = (mapInfo) => async (dispatch, getState) => {
  const { id } = mapInfo;
  await mapsRef.doc(id).delete();
};

export const leaveMap = (mapInfo) => async (dispatch, getState) => {
  const { email } = getState().auth;
  const newCreator = mapInfo.creator.filter((user) => user.email !== email);
  mapsRef.doc(mapInfo.id).update({ creator: newCreator });
};

export const saveCycles = (cycles) => ({
  type: SAVE_CYCLES,
  payload: cycles,
});

export const saveSelectedLoopArea = (
  centerPosition,
  areaSize,
  loop = false
) => ({
  type: SAVE_SELECTED_LOOP_AREA,
  payload: { centerPosition, areaSize, loop },
});

export const setLoopView = (loop, isHideFully, unUsepoint) => ({
  type: SET_LOOP_VIEW,
  payload: loop,
  isHideFully: isHideFully,
  unUsepoint: unUsepoint,
});

export const showHoveringLoop = (loop) => ({
  type: SHOW_LEVERAGE_LOOP,
  payload: loop,
});

export const startedNewMap = (b) => ({
  type: CREATED_NEW_MAP,
  payload: b,
});

export const changeColorSystem = (b) => ({
  type: CHANGE_COLOR_SYSTEM,
  payload: b,
});

export const clearMaps = () => async (dispatch) => {
  if (unSubscribe) {
    unSubscribe();
  }
  if (unSubscribeWays) {
    unSubscribeWays();
  }
  if (unSubscribeNodes) {
    unSubscribeNodes();
  }

  dispatch({ type: CLEAR_MAPS });
};
