import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import {
  Button,
  Fab,
  Container,
  Dialog,
  DialogContent,
  TextField,
  Tooltip,
  DialogTitle,
} from "@material-ui/core";

import ConfirmDialog from "./ConfirmDialog";

import { removeFocus } from "../../utils/global"; //extractContent
import "../../styles/components/Canvas/PointEditDialog.scss";
import "react-quill/dist/quill.snow.css";
import moment from "moment";

const PointEditDialog = ({
  open,
  pointData,
  onCloseModal,
  updatePoint,
  onDelete,
}) => {
  const { selectedMapData } = useSelector((state) => state.node);
  const [addNote, setAddNote] = useState(false);
  const [updatedData, setUpdatedData] = useState({
    title: "",
    measure: "",
    note: "",
    kpi: false,
    allowLeverage: false,
    fog: false,
    created_date: "",
    creator_name: "",
    editor_name: "",
    updated_date: "",
  });
  const [isOpenDlg, setIsOpenDlg] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [isESC, setIsESC] = useState(false);
  const [isTrue, setCanges] = useState(false);
  const [note, setNote] = useState(false);
  const [clickname, setClick_Name] = useState("");
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (open) {
      removeFocus();
    }
  }, [open]);

  useEffect(() => {
    const onKeyEventHandler = (evt) => {
      if (evt.keyCode === 13) {
        if (isTrue) {
          if (!isFocused) {
            updatePoint(updatedData);
            setAddNote(false);
            setNote(false);
            onCloseModal();
          }
        } else {
          setCanges(false);
          setAddNote(false);
          setNote(false);
          onCloseModal();
        }
      }
      if (evt.keyCode === 27) {
        if (addNote) {
          setCanges(false);
          setAddNote(false);
        } else {
          setCanges(false);
          setAddNote(false);
          setNote(false);
          onCloseModal();
        }
      }
      if (evt.keyCode === 9) {
        setClick_Name("");
      }
    };
    document.addEventListener("keydown", onKeyEventHandler);
    return () => {
      document.removeEventListener("keydown", onKeyEventHandler);
    };
  }, [isTrue, updatedData, updatePoint, open, note, clickname]);

  useEffect(() => {
    if (
      !selectedMapData ||
      !selectedMapData.data ||
      !selectedMapData.data.length === 0
    ) {
      if (open) {
        setAddNote(false);
        onCloseModal();
      }
    }
  }, [selectedMapData?.data, onCloseModal, open]);

  useEffect(() => {
    if (pointData) {
      // if (
      //   extractContent(pointData.note) &&
      //   extractContent(pointData.note) !== " "
      // ) {
      //   setAddNote(true);
      // } else {
      //   setAddNote(false);
      // }
      const date = moment(pointData?.created_date, "DD MM YYYY").format(
        "MMM DD, YYYY"
      );
      setUpdatedData({
        ...pointData,
        created_date:
          date === "Invalid date" ? pointData?.created_date || null : date,
      });
    } else {
      setUpdatedData({
        title: "",
        measure: "",
        note: "",
        kpi: false,
        allowLeverage: false,
        fog: false,
        created_date: "",
        creator_name: "",
        editor_name: "",
        updated_date: "",
      });
    }
  }, [pointData]);

  const showNoteArea = () => {
    setAddNote(!addNote);
  };
  const showNote = () => {
    setNote(true);
  };

  const handleFocus = () => setFocused(true);

  const handleChange = (event) => {
    setCanges(true);
    setFocused(false);
    const newUpdatedData = {
      ...updatedData,
      [event.target.name]: event.target.value,
      editor_name: auth.displayName
        ? auth.displayName
        : sessionStorage.getItem("guestLogin"),
      updated_date: moment(new Date()).format("MMM DD, YYYY"),
    };
    setUpdatedData(newUpdatedData);
  };

  const handleChangeNote = (text) => {
    setUpdatedData({
      ...updatedData,
      note: text,
      editor_name: auth.displayName
        ? auth.displayName
        : sessionStorage.getItem("guestLogin"),
      updated_date: moment(new Date()).format("MMM DD, YYYY"),
    });
    setCanges(true);
  };

  const applyUpdatedData = () => {
    updatePoint(updatedData);
    setFocused(false);
    if (addNote) {
      setAddNote(false);
      setCanges(false);
      setNote(false);
    } else {
      setCanges(false);
      setAddNote(false);
      setNote(false);
      onCloseModal();
    }
  };

  const toggleKpi = () => {
    const newUpdatedData = {
      ...updatedData,
      kpi: !updatedData.kpi,
      editor_name: auth.displayName
        ? auth.displayName
        : sessionStorage.getItem("guestLogin"),
      updated_date: moment(new Date()).format("MMM DD, YYYY"),
    };
    setUpdatedData(newUpdatedData);
    setCanges(true);
  };

  const toggleLeverage = () => {
    const newUpdateData = {
      ...updatedData,
      allowLeverage: !updatedData.allowLeverage,
      editor_name: auth.displayName
        ? auth.displayName
        : sessionStorage.getItem("guestLogin"),
      updated_date: moment(new Date()).format("MMM DD, YYYY"),
    };
    setUpdatedData(newUpdateData);
    setCanges(true);
  };

  const toggleFogMode = () => {
    const newUpdatedData = {
      ...updatedData,
      fog: !updatedData.fog,
      editor_name: auth.displayName
        ? auth.displayName
        : sessionStorage.getItem("guestLogin"),
      updated_date: moment(new Date()).format("MMM DD, YYYY"),
    };
    setUpdatedData(newUpdatedData);
    setCanges(true);
  };

  const openDeletePointDlg = () => {
    setIsOpenDlg(true);
  };

  const onCloseConfirmModal = () => {
    setIsOpenDlg(false);
    setIsESC(false);
    setAddNote(false);
    setNote(false);
  };

  const onDeletePoint = () => {
    setIsOpenDlg(false);
    onDelete(pointData);
  };

  const getTitle = () => {
    if (!updatedData) return "Variable";

    if (updatedData.allowLeverage) {
      return "Leverage Point";
    }

    if (updatedData.isLeveragePoint) {
      return "Focal Point";
    }

    if (updatedData.comein && updatedData.comein.length > 1) {
      return "Strong Point";
    }

    return "Variable";
  };

  const CloseModal = () => {
    if (addNote) {
      setAddNote(false);
      setIsESC(false);
      setNote(false);
    } else {
      setIsESC(false);
      setCanges(false);
      onCloseModal();
      setAddNote(false);
    }
  };

  const CloseConformModal = () => {
    if (isTrue) {
      setIsESC(true);
    } else {
      setIsESC(false);
      setAddNote(false);
      setNote(false);
      onCloseModal();
    }
  };

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      if (isTrue) {
      }
    } else if (evt.key === "Escape" && !isESC) {
      if (isTrue) {
        setIsESC(true);
      } else {
        setIsESC(false);
        setAddNote(false);
        onCloseModal();
      }
    } else if (evt.key === "Escape") {
      setIsESC(false);
    }
  };
  const click_Name = () => {
    setClick_Name("fontColor");
    setFocused(false);
  };
  const click_measure = () => {
    setClick_Name("");
    setFocused(false);
  };
  return (
    <>
      <Dialog
        BackdropProps={{ invisible: false }} //disableBackdropClick
        open={open}
        onClose={handleKeyPress}
        onKeyDown={handleKeyPress}
      >
        <DialogContent>
          {!addNote ? (
            <div className="header">
              <h1>{`${getTitle()} ${
                updatedData && updatedData.kpi ? "(Key Indicator)" : ""
              }`}</h1>
              <div className="actions-group" onClick={() => setFocused(false)}>
                <Button onClick={toggleKpi}>
                  {updatedData && updatedData.kpi ? (
                    <Tooltip title="Revert to Variable" leaveDelay={200}>
                      <img src="/assets/ico-star-fill.svg" alt="" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Make Key Indicator" leaveDelay={200}>
                      <img src="/assets/ico-star-outline.svg" alt="" />
                    </Tooltip>
                  )}
                </Button>
                <Button onClick={toggleLeverage}>
                  {updatedData && updatedData.allowLeverage ? (
                    <Tooltip title="Revert to Focal Point" leaveDelay={200}>
                      <img
                        src="/assets/ico-allow-leverage.svg"
                        style={{ opacity: 1 }}
                        alt=""
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Make Leverage Point" leaveDelay={200}>
                      <img src="/assets/ico-allow-focal.svg" alt="" />
                    </Tooltip>
                  )}
                </Button>
                <Button onClick={toggleFogMode}>
                  {updatedData && updatedData.fog ? (
                    <Tooltip title="Remove Fog" leaveDelay={200}>
                      <img src="/assets/ico-fog-fill.svg" alt="" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add Fog" leaveDelay={200}>
                      <img src="/assets/ico-fog-outline.svg" alt="" />
                    </Tooltip>
                  )}
                </Button>
                <Button onClick={openDeletePointDlg}>
                  <Tooltip title={`Delete ${getTitle()}`} leaveDelay={200}>
                    <img src="/assets/ico-delete.svg" alt="" />
                  </Tooltip>
                </Button>
              </div>
            </div>
          ) : (
            <div className="header">
              <img src="/assets/ico-notes.svg" alt="" />
              <h1 style={{ width: "100%", paddingLeft: "10px" }}>
                {updatedData.title}
              </h1>
            </div>
          )}
          <div className="content" style={{ height: addNote ? 390 : "auto" }}>
            {!addNote ? (
              <>
                <TextField
                  id="standard-name"
                  label="Name"
                  name="title"
                  placeholder={`How will you name this ${getTitle()}?`}
                  value={updatedData.title}
                  onKeyPress={handleKeyPress}
                  inputProps={{ maxLength: 70 }}
                  onChange={handleChange}
                  onClick={click_Name}
                />
                <span
                  className={`textfield_font ${clickname}`}
                >{`${updatedData.title.length}/70`}</span>
                <TextField
                  id="standard-measure"
                  label="Measure"
                  name="measure"
                  placeholder={`How will you measure "${updatedData.title}"?`}
                  onKeyPress={handleKeyPress}
                  value={updatedData.measure}
                  onChange={handleChange}
                  onClick={click_measure}
                />
                {(updatedData.note !== "" || note) && (
                  <>
                    <label className="lab-note">Note</label>
                    <div className="testfiel" onClick={showNoteArea}>
                      <div className="app first">
                        <ReactQuill
                          value={updatedData.note}
                          onChange={handleChangeNote}
                          onFocus={handleFocus}
                          className={`txt-area-note ${
                            isFocused ? "isFocused" : ""
                          }`}
                          onKeyPress={handleKeyPress}
                          placeholder="Add your note here."
                          bounds={".app"}
                          name="note"
                        ></ReactQuill>
                      </div>
                      <img src="/assets/ico-edit-note.svg" alt="" />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="app">
                  <ReactQuill
                    value={updatedData.note}
                    onChange={handleChangeNote}
                    onFocus={handleFocus}
                    className={`txt-area-note ${isFocused ? "isFocused" : ""}`}
                    onKeyPress={handleKeyPress}
                    placeholder="Add your note here."
                    bounds={".app"}
                    name="note"
                  >
                    {/* <div className="my-editing-area" /> */}
                  </ReactQuill>
                </div>
              </>
            )}
          </div>
          <div
            className="actions"
            style={{
              flexDirection:
                updatedData.note !== "" || note ? "row-reverse" : "",
            }}
            onClick={() => setFocused(false)}
          >
            {updatedData.note === "" && !note && (
              <Button
                className="add_note"
                onClick={showNote}
                variant="contained"
                disableElevation
              >
                <img src="/assets/notes.svg" alt=""></img>
                Add Note
              </Button>
            )}
            <div className="modal-actions">
              <Button
                onClick={() => {
                  !addNote ? CloseConformModal() : setAddNote(false);
                }}
                variant="outlined"
                size="large"
                className="cancle"
              >
                CLOSE
              </Button>
              <Fab
                className={!addNote ? "" : "Button_color"}
                onClick={applyUpdatedData}
                variant="extended"
                size="large"
                style={{ width: !addNote ? "120px" : "150px" }}
              >
                {!addNote ? "SAVE" : "SAVE AND CLOSE"}
              </Fab>
            </div>
          </div>
        </DialogContent>
        {updatedData.creator_name && (
          <span className="modal-last-line">
            {`Created by ${updatedData.creator_name} on ${updatedData.created_date}.`}{" "}
            {updatedData.updated_date &&
              `Last edited by ${updatedData.editor_name} on ${updatedData.updated_date}.`}
          </span>
        )}
      </Dialog>
      <ConfirmDialog
        open={isOpenDlg}
        title={`Delete ${pointData?.title || ""}`}
        description="Do you want to delete this variable?"
        okButtonLabel="Ok"
        cancelButtonLabel="Cancel"
        onClosed={onCloseConfirmModal}
        onConfirmed={onDeletePoint}
      />
      <Dialog
        style={{ zIndex: 1303 }}
        BackdropProps={{ invisible: false }} //disableBackdropClick
        className="green-modal"
        open={isESC}
        onKeyDown={handleKeyPress}
      >
        <DialogTitle className="green-modal-header">
          {"Unsaved changes"}
        </DialogTitle>
        <DialogContent className="green-modal-body">
          <p>
            {
              "You have unsaved changes. Are you sure you want to close without saving?"
            }
          </p>
        </DialogContent>
        <Container className="green-modal-footer">
          <Button
            className="btn-outline"
            variant="outlined"
            onClick={CloseModal}
          >
            {"Close without saving"}
          </Button>
          <Button className="btn-green" onClick={onCloseConfirmModal}>
            {"Keep editing"}
          </Button>
        </Container>
      </Dialog>
    </>
  );
};

export default React.memo(PointEditDialog);
