import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Button, TextField } from "@material-ui/core";

import { handleAddMember, logout } from "../redux/actions/authAction";
import { getAllUsers, getAllMapsInfo } from "../services";
import "react-toastify/dist/ReactToastify.css";
import "../styles/components/Login.scss";

const AdminDashboard = ({ history }) => {
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [orgErr, setOrgErr] = useState("");

  const dispatch = useDispatch();
  const { addMemberResult, isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (addMemberResult && !isLoading) {
      toast("Added member Successfully!", {
        className: "toastre",
      });
    } else if (!isLoading && addMemberResult === false) {
      toast("Failed to add a member", {
        className: "toastre-error",
      });
    }
  }, [addMemberResult, isLoading]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "firstname") {
      setFirstName(value);
    } else if (name === "lastname") {
      setLastName(value);
    } else if (name === "organization") {
      setOrganization(value);
    }
  };

  const handleLogout = (event) => {
    dispatch(logout());
    history.push("/login");
  };

  const isValidation = () => {
    let isNoValid = false;
    if (!firstName) {
      setFirstNameErr("First Name is required");
      isNoValid = true;
    } else {
      setFirstNameErr("");
    }

    if (!lastName) {
      setLastNameErr("Last Name is required");
      isNoValid = true;
    } else {
      setLastNameErr("");
    }

    if (!organization) {
      setOrgErr("Organization is required");
      isNoValid = true;
    } else {
      setOrgErr("");
    }

    if (
      !email ||
      !email.match(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
    ) {
      setEmailErr("Email is not valid");
      isNoValid = true;
    } else {
      setEmailErr("");
    }

    return !isNoValid;
  };

  const addMember = () => {
    if (isValidation()) {
      dispatch(handleAddMember({ email, organization, firstName, lastName }));
    }
  };

  return (
    <div className="login-container">
      <header className="login-header">
        <img src="/assets/img-logo-txt.svg" alt="" />
        <div className="btn-group">
          <Button variant="text" className="btn-login" onClick={getAllUsers}>
            Download User List
          </Button>
          <Button variant="text" className="btn-login" onClick={getAllMapsInfo}>
            Download User Activity
          </Button>
          <Button variant="text" className="btn-login" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </header>
      <div className="login-card">
        <h1 className="login-card-header">Add Member</h1>
        <TextField
          className="input-form"
          label="First Name"
          error={Boolean(firstNameErr)}
          helperText={firstNameErr}
          name="firstname"
          value={firstName}
          onChange={handleChange}
        />
        <TextField
          className="input-form"
          label="Last Name"
          error={Boolean(lastNameErr)}
          helperText={lastNameErr}
          name="lastname"
          value={lastName}
          onChange={handleChange}
        />
        <TextField
          className="input-form"
          type="text"
          label="Organization"
          error={Boolean(orgErr)}
          helperText={orgErr}
          name="organization"
          value={organization}
          onChange={handleChange}
        />
        <TextField
          className="input-form"
          label="Email"
          error={Boolean(emailErr)}
          helperText={emailErr}
          name="email"
          value={email}
          onChange={handleChange}
        />
        <Button onClick={addMember} className="btn-signup">
          Add Member
        </Button>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AdminDashboard;
