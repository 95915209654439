export const sendEmailTo = async ({ to, from, subject, text, html }) => {
  const msg = {
    to,
    from,
    subject,
    text,
    html,
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(msg),
  };
  await fetch("/api/sendMsg", requestOptions);
};

export const getUserInfoByEmail = async ({ email }) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };
  try {
    const response = await fetch("/api/getUserByEmail", requestOptions);
    return response.json();
  } catch (err) {
    console.log("error: ", err);
    return null;
  }
};

export const allowTermsForCurrentUsers = async () => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };
  try {
    const response = await fetch(
      "/api/allowTermsForCurrentUsers",
      requestOptions
    );
    const data = await response.json();
    if (data.status === "success") {
      return true;
    }
  } catch (err) {
    console.log("error: ", err);
    return false;
  }
};

export const getAllUsers = async () => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };
  try {
    const response = await fetch("/api/getAllUsers", requestOptions);
    const data = await response.json();
    let csvContent =
      "data:text/csv;charset=utf-8," +
      data.data.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);

    const dateObj = new Date();
    const year = dateObj.getFullYear() - 2000;
    const month = dateObj.getMonth() + 1;
    const date = dateObj.getDate();

    link.setAttribute("download", `User List_${month}.${date}.${year}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  } catch (err) {
    console.log("error: ", err);
  }
};

export const getAllMapsInfo = async () => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };
  try {
    const response = await fetch("/api/getAllMapsInfo", requestOptions);
    const data = await response.json();
    let csvContent = data.data
      .map((e) => {
        const converted = e.map((word, idx) => {
          if (idx === 0) {
            return word ? word.replace(/[^0-9a-z ]/gi, "") : "";
          }
          return word || "";
        });
        return converted.join(",");
      })
      .join("\n");
    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,\uFEFF" + encodeURI(csvContent)
    );

    const dateObj = new Date();
    const year = dateObj.getFullYear() - 2000;
    const month = dateObj.getMonth() + 1;
    const date = dateObj.getDate();

    link.setAttribute("download", `User Activity_${month}.${date}.${year}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  } catch (err) {
    console.log("error: ", err);
  }
};

export const updateUserInfo = async (userInfo) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userInfo }),
  };
  try {
    await fetch("/api/updateUserInfo", requestOptions);
  } catch (err) {
    console.log("error: ", err);
  }
};
