import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { NotSupportSize } from "./NotSupport";
import TitleComponent from "../utils/TitleComponent";
import CanvasComponent from "../components/Canvas";
import ChatBar from "../components/ChatBar";
import SideBar from "../components/SideBar";
// import TimelineBar from "../components/TimelineBar";
import FeedbackBar from "../components/FeedbackBar";
import TopBar from "../components/TopBar";
import NotesBar from "../components/NoteBar";
import Help from "../components/Help";
import { getDeviceType } from "../utils/global";
import { Button, Dialog, TextField } from "@material-ui/core";
import {
  getMapsByToken,
  selectMap,
  startedNewMap,
  addPartipant,
  addGuestPartipant,
  updateGuestTimestamp,
} from "../redux/actions/nodeAction";
import { saveFolders, getTemplate } from "../redux/actions/folderAction";
import useNetworkConnection from "../hooks/useNetworkConnection";
import { getMovements } from "../redux/actions/movementAction";

import "../styles/App.scss";
import "../styles/components/GetMapByToken.scss";
import { handleLogin } from "../redux/actions/authAction";
import { tokenRef } from "../core/firebase";

const GetMapByToken = () => {
  const { type } = useParams();
  const history = useHistory();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openChatbar, setOpenChatbar] = useState(false);
  const [openFeedbackBar, setOpenFeedbackBar] = useState(false);
  const [editableTitle, setEditableTitle] = useState(false);
  const [focusedCycle, setFocuedCycle] = useState(null);
  const [OpenNotesBar, setOpenNotesBar] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const { selectedMapData } = useSelector((state) => state.node);
  const { permission } = useSelector((state) => state.auth);
  const { isDisconnected } = useNetworkConnection();
  const dispatch = useDispatch();
  const [dropValue, setDrovalue] = useState(null);
  const [InviteGuest, setGuest] = useState(true);
  const [InviteGuest_class, setGuest_class] = useState("invite-title");
  const [InviteRegister_class, setRegister_class] = useState("");
  const [open, setDialogOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { errCode } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [guestFirstName, setGuestFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [isPartipantadd, isaddPartipant] = useState(false);
  const [windowWidth, setWindowWidth] = useState();
  var result = type.substr(type.lastIndexOf("/") + 1);
  var guestId = result.substr(0, result.indexOf("-"));
  const [statusOfVisibility, setStatusOfVisibility] = useState(null);
  const [PlayStatus, setPlayStatus] = useState("");
  const [showVariableText, setShowVariableText] = useState(true);
  // const [showAnimations, setShowAnimations] = useState(true);
  const [lowResMode, setLowResMode] = useState(false);
  const [OpenHelp, setOpenHelp] = useState(false);
  const [isShowTopProgressBar, setShowTopProgressBar] = useState(true);
  // const [isLengthOfFeedbackLoop, setIsLengthOfFeedbackLoop] = useState(0);

  useEffect(() => {
    if (guestId && auth.email == null) {
      dispatch(getMapsByToken(guestId));
      dispatch(getMovements());
    }
  }, [guestId, dispatch, auth.email]);
  useEffect(() => {
    if (auth.email) {
      dispatch(getMovements());
    }
  });
  useEffect(() => {
    if (selectedMapData == null) {
    } else {
      if (selectedMapData?.creator) {
        selectedMapData.creator.map((value) => {
          if (
            auth.email === value.email ||
            localStorage.getItem("random_id") === value.random_id
          ) {
            setDrovalue(value.dropvalue);
          }
        });
        if (selectedMapData && selectedMapData.tag === "starter") {
          setGuest(false);
        }
      }
    }
  }, [selectedMapData, auth.email]);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(updateGuestTimestamp(sessionStorage.getItem("random_id")));
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch]);
  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const resize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (auth.email) {
      if (!isPartipantadd) {
        if (selectedMapData && selectedMapData.id) {
          dispatch(
            selectMap(
              selectedMapData && selectedMapData.id,
              guestId,
              auth.email
            )
          );
          dispatch(startedNewMap(true));
          dispatch(saveFolders(auth.uid));
          dispatch(getTemplate());
          const docRef = tokenRef.doc(guestId);
          docRef.get().then(async (snapshot) => {
            const mapData = snapshot.data();
            await dispatch(
              addPartipant([auth.email], mapData.drop_value, true)
            );
          });
          history.push("/main");
        }
      } else {
        dispatch(
          selectMap(selectedMapData && selectedMapData.id, guestId, auth.email)
        );
        dispatch(saveFolders(auth.uid));
        dispatch(getTemplate());
        dispatch(startedNewMap(true));
        history.push("/main");
      }
    } else if (sessionStorage.getItem("guestLogin")) {
      if (selectedMapData) {
        var loginDialog =
          selectedMapData.creator &&
          selectedMapData.creator.filter(
            (item) => sessionStorage.getItem("random_id") === item.random_id
          );
        if (loginDialog && loginDialog.length === 0) {
          history.push("/login");
          sessionStorage.removeItem("random_id");
          sessionStorage.removeItem("guestLogin");
          sessionStorage.removeItem("guestRole");
        } else {
          setDialogOpen(false);
        }
      } else {
        setDialogOpen(true);
        setOpenSidebar(false);
      }
    } else if (!sessionStorage.getItem("guestLogin")) {
      if (selectedMapData) {
        setDialogOpen(true);
      }
    }
  }, [
    auth.email,
    auth.uid,
    isPartipantadd,
    selectedMapData,
    dispatch,
    guestId,
    history,
  ]);

  const showSidebar = () => {
    if (open === false) {
      setOpenSidebar(!openSidebar);
    }
  };

  const showChatbar = () => {
    setOpenFeedbackBar(false);
    setOpenChatbar(true);
  };

  const showTimelineBar = () => {
    setOpenChatbar(false);
  };

  const closeSidebar = () => {
    setOpenSidebar(false);
  };

  const showFeedbackBar = () => {
    setOpenFeedbackBar(true);
    setOpenChatbar(false);
  };

  const closeFeedbackBar = () => {
    setOpenFeedbackBar(false);
  };

  const closeChatbar = () => {
    setOpenChatbar(false);
  };

  const setBackDrop = () => {
    closeSidebar();
  };

  const setEnableEditTitle = () => {
    if (dropValue === "Owner" || dropValue === "Editor") {
      if (openSidebar) {
        setTimeout(() => {
          setEditableTitle(true);
        }, 1000);
      } else {
        setEditableTitle(true);
      }
      setOpenSidebar(false);
    }
  };

  const closeEditableText = () => {
    setEditableTitle(false);
  };

  const Guest = () => {
    setGuest(true);
    setRegister_class("");
    setGuest_class("invite-title");
  };
  const Invite_link = () => {
    setGuest(false);
    setRegister_class("invite-title");
    setGuest_class("");
  };

  const isValidation = () => {
    let isNoValid = false;
    if (!password) {
      setPasswordErr("Password is required");
      isNoValid = true;
    } else {
      setPasswordErr("");
    }

    if (
      !email ||
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      setEmailErr("Please enter a valid e-mail address");
      isNoValid = true;
    } else {
      setEmailErr("");
    }

    return !isNoValid;
  };

  const isGuestValidation = () => {
    let isValidGuestName = true;
    if (!guestFirstName) {
      setFirstNameErr("First Name is required");
      isValidGuestName = false;
      return isValidGuestName;
    } else {
      return isValidGuestName;
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "guestFirstName") {
      setGuestFirstName(value);
    }
  };

  const submitGuest = async () => {
    if (isGuestValidation() && selectedMapData.creator) {
      setGuestFirstName("");
      const docRef = tokenRef.doc(guestId);

      docRef.get().then(async (snapshot) => {
        const mapData = snapshot.data();
        sessionStorage.setItem("guestRole", mapData.drop_value);
        isaddPartipant(true);
        var random_id = Math.random().toString(36).substr(2, 9);
        await dispatch(
          addGuestPartipant(
            guestFirstName.trim(),
            mapData.drop_value,
            random_id
          )
        );

        // const checkDialogOpen =
        //   selectedMapData &&
        //   selectedMapData.creator.filter(
        //     (item) => sessionStorage.getItem("random_id") === item.random_id
        //   );
        // if (checkDialogOpen.length > 0) {
        setDialogOpen(false);
        sessionStorage.setItem("random_id", random_id);
        sessionStorage.setItem("guestLogin", guestFirstName.trim());
        // }
      });
    }
  };

  const login = () => {
    if (isValidation()) {
      const docRef = tokenRef.doc(guestId);
      docRef.get().then((snapshot) => {
        const mapData = snapshot.data();
        isaddPartipant(true);
        dispatch(handleLogin({ email, password }, mapData.drop_value));
      });
    }
  };

  const gotoForgotPassword = () => {
    history.push("/change-password/forgot");
  };
  const Register = () => {
    window.open("https://cauzality.com/sign-up");
  };
  const onPlayLoopOnce = (value) => {
    setPlayStatus({ value: value, playloop: "once" });
  };
  const onPlayLoopRepeat = (value) => {
    setPlayStatus({ value: value, playloop: "repeat" });
  };
  const showHelp = () => {
    setOpenFeedbackBar(false);
    setOpenChatbar(false);
    // setOpenTimelineBar(false);
    // setOpenGroupsBar(false);
    setOpenHelp(true);
  };
  const closeHelp = () => {
    setOpenHelp(false);
  };
  // const AllPlayloop = () => {
  //   let allFocusedCycle = [];
  //   focusedCycle &&
  //     focusedCycle.map((item) => {
  //       item.data.map((value) => {
  //         allFocusedCycle.push(value);
  //       });
  //     });
  //   setPlayStatus({
  //     value: allFocusedCycle,
  //     playloop: "once",
  //     onplay: `${allFocusedCycle.length - 1}once`,
  //   });
  // };
  const handleChangeVariableText = () => {
    setShowVariableText(!showVariableText);
  };
  // const handleChangeShowAnimations = () => {
  // setShowAnimations(!showAnimations);
  // }
  const handleShowProgressbar = () => {
    setShowTopProgressBar(!isShowTopProgressBar);
  };
  const handleChangeLowResMode = () => {
    setLowResMode(!lowResMode);
  };
  const closeNotesBar = () => {
    setOpenNotesBar(false);
  };
  const addNote = () => {
    setOpenNote(true);
    closeNotesBar();
  };
  const showNotesBar = () => {
    setOpenFeedbackBar(false);
    setOpenChatbar(false);
    // setOpenTimelineBar(false);
    setOpenHelp(false);
    setOpenNotesBar(true);
  };
  if (getDeviceType() !== "desktop" || windowWidth < 967) {
    return <NotSupportSize />;
  }

  return (
    <>
      <TitleComponent
        title={
          selectedMapData
            ? `${selectedMapData.name} - Cauzality`
            : "Map Library"
        }
      />
      <TopBar
        editableTitle={editableTitle}
        setEnableEditTitle={setEnableEditTitle}
        closeEditableText={closeEditableText}
        showSidebar={showSidebar}
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
        showChatbar={showChatbar}
        focusedCycle={focusedCycle}
        showTimelineBar={showTimelineBar}
        showFeedbackBar={showFeedbackBar}
        openFeedbackBar={openFeedbackBar}
        closeFeedbackBar={closeFeedbackBar}
        showHelp={showHelp}
        openhelp={OpenHelp}
        openchat={openChatbar}
        closeChatbar={closeChatbar}
        setCloseHelp={() => setOpenHelp(false)}
        status={statusOfVisibility}
        onChangeVisibility={setStatusOfVisibility}
        showVariableText={showVariableText}
        showNotesBar={showNotesBar}
        setFocuedCycle={setFocuedCycle}
        isShowTopProgressBar={isShowTopProgressBar}
      />
      <CanvasComponent
        onShowFeedbackBar={showFeedbackBar}
        status={statusOfVisibility}
        playstatus={PlayStatus}
        data_loop={onPlayLoopOnce}
        showVariableText={showVariableText}
        setBackDrop={setBackDrop}
        // showAnimations={showAnimations}
        lowResMode={lowResMode}
        openNote={openNote}
        CloseNote={() => setOpenNote(false)}
        setFocuedCycle={() => setFocuedCycle(null)}
      />
      <SideBar
        open={openSidebar}
        userType={permission}
        onClose={closeSidebar}
        handleChangeVariableText={handleChangeVariableText}
        // handleChangeShowAnimations={handleChangeShowAnimations}
        handleChangeLowResMode={handleChangeLowResMode}
        showVariableText={showVariableText}
        // showAnimations={showAnimations}
        lowResMode={lowResMode}
        handleShowProgressbar={handleShowProgressbar}
        isShowTopProgressBar={isShowTopProgressBar}
      />
      <FeedbackBar
        focusedCycle={focusedCycle}
        setFocuedCycle={setFocuedCycle}
        open={openFeedbackBar}
        onClose={closeFeedbackBar}
        onPlayLoopOnce={(value) => onPlayLoopOnce(value)}
        onPlayLoopRepeat={(value) => onPlayLoopRepeat(value)}
        setStatusOfVisibility={() => setStatusOfVisibility()}
        // setIsLengthOfFeedbackLoop={setIsLengthOfFeedbackLoop}
        // isLengthOfFeedbackLoop={isLengthOfFeedbackLoop}
        // AllPlayloop={()=>AllPlayloop()}
      />
      <NotesBar open={OpenNotesBar} onClose={closeNotesBar} addNote={addNote} />
      <Help open={OpenHelp} onClose={closeHelp} />
      <ChatBar
        open={openChatbar}
        onClose={closeChatbar}
        dropValue={dropValue}
      />
      {isDisconnected && (
        <div className="offline-error">
          We are unable to connect. Your changes are no longer being saved.
          <div onClick={() => window.location.reload()}>Reload Page</div>
        </div>
      )}
      <Dialog
        BackdropProps={{ invisible: false }} //disableBackdropClick
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ margin: "110px 32px 32px 32px" }}
      >
        <div className="solid">
          {selectedMapData && selectedMapData.tag === "starter" ? (
            <span
              className="invite-title"
              style={{ marginBottom: "-1px", cursor: "pointer" }}
            >
              Sign In/Register
            </span>
          ) : (
            <>
              <span
                className={`${InviteGuest_class} mr-3`}
                style={{ marginBottom: "-1px", cursor: "pointer" }}
                onClick={Guest}
              >
                Join as Guest
              </span>
              <span
                className={`${InviteRegister_class} ml-3`}
                style={{ marginBottom: "-1px", cursor: "pointer" }}
                onClick={Invite_link}
              >
                Sign In/Register
              </span>
            </>
          )}
        </div>
        {InviteGuest ? (
          <div className="invite-dlg" show="false">
            <h3 className="invite-dlg-title">
              Join the map during <u>this session only.</u> To be able to <br />{" "}
              access this map again later,{" "}
              <span className="signIn" onClick={Invite_link}>
                sign in or register
              </span>
              .
            </h3>
            <TextField
              error={firstNameErr}
              className="input-form"
              label="First Name*"
              name="guestFirstName"
              onChange={(e) => handleChange(e)}
              helperText={firstNameErr}
            />
            <div className="button-container-div">
              <Button
                variant="contained"
                className="btn-invite"
                onClick={submitGuest}
              >
                Join Map as a Guest
              </Button>
            </div>
          </div>
        ) : (
          <div className="invite-dlg" show="false">
            <h3 className="invite-dlg-title">
              Sign in to your account and join now. The map <br /> will also
              appear in your map library.
            </h3>
            <TextField
              error={
                errCode == null
                  ? emailErr
                  : errCode === "auth/user-not-found" ||
                    errCode === "auth/user-disabled"
              }
              className="input-form"
              label="Email*"
              name="email"
              value={email}
              onChange={(e) => handleChange(e)}
              helperText={
                errCode == null
                  ? emailErr
                  : errCode === "auth/user-not-found"
                  ? "We are unable to find an account with this email. Please try again."
                  : errCode === "auth/user-disabled"
                  ? "This account is no longer active."
                  : ""
              }
            />
            <TextField
              error={
                errCode == null
                  ? passwordErr
                  : errCode === "auth/wrong-password" ||
                    errCode === "auth/too-many-requests"
              }
              className="input-form"
              type="password"
              label="Password*"
              name="password"
              value={password}
              onChange={(e) => handleChange(e)}
              helperText={
                errCode == null
                  ? passwordErr
                  : errCode === "auth/wrong-password"
                  ? "Incorrect password"
                  : errCode === "auth/too-many-requests"
                  ? "Too many failed login attempts. Please try again later."
                  : ""
              }
            />
            <Button onClick={gotoForgotPassword} className="forgot">
              Forgot password?
            </Button>
            <div className="button-container-div">
              <Button
                variant="contained"
                className="btn-invite"
                onClick={login}
              >
                Sign In and Join Map
              </Button>
            </div>
            <h3 className="invite-dlg-title">
              Need an account?{" "}
              <span className="signIn" onClick={Register}>
                Register
              </span>{" "}
              for free <br /> (no credit card required).
            </h3>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default GetMapByToken;
